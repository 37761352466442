
<template>
<div class="index-list-index">
        <navigationBar :routerList="this.$router.history.current.meta.breadcrumb" />
        <div >
            <div class="echart-info" id="info-data"></div>
        </div>
    </div>
      
</template>

<script>
import echarts from "echarts";
import { findAllWorkOderPage } from '@/api/getBaseData'
import cookies from '@/utils/cookies/index.js'
import navigationBar from '@/components/navigationBar'

import { mapGetters } from 'vuex'

export default {
  data (){
    return {
      dataEchart:"",
      user:{},
      // buttonList:[
      //     {
      //       backgroundColor:'#52D1AF',
      //       icon:'/static/img/add.png',
      //       text:'新增',
      //     }
          
      //   ],
    }
  },
 
  activated(){


     let uid = cookies.get("uid");  
     this.user = JSON.parse(localStorage.getItem('userInfo'+ uid))
  },
  mounted(){
     this.doEchart();
    // this.findAdminPage();
  },
 
  components:{
    navigationBar
  },
  methods:{
    doEchart: function() {
       //提交名为addmu的mutations
       console.log("this.$store",this.$store)
       this.dataEchart = echarts.init(document.getElementById("info-data"),"light");
       this.doEchartData();
    },
    doEchartData: function() {
      let  option = {
            legend: {},
            tooltip: {},
            dataset: {
                source: [
                    ['product', '2012', '2013', '2014', '2015'],
                    ['Matcha Latte', 41.1, 30.4, 65.1, 53.3],
                    ['Milk Tea', 86.5, 92.1, 85.7, 83.1],
                    ['Cheese Cocoa', 24.1, 67.2, 79.5, 86.4]
                ]
            },
            xAxis: [
                {type: 'category', gridIndex: 0},
                {type: 'category', gridIndex: 1}
            ],
            yAxis: [
                {gridIndex: 0},
                {gridIndex: 1}
            ],
            grid: [
                {bottom: '55%'},
                {top: '55%'}
            ],
            series: [
                // These series are in the first grid.
                {type: 'bar', seriesLayoutBy: 'row'},
                {type: 'bar', seriesLayoutBy: 'row'},
                {type: 'bar', seriesLayoutBy: 'row'},
                // These series are in the second grid.
                {type: 'bar', xAxisIndex: 1, yAxisIndex: 1},
                {type: 'bar', xAxisIndex: 1, yAxisIndex: 1},
                {type: 'bar', xAxisIndex: 1, yAxisIndex: 1},
                {type: 'bar', xAxisIndex: 1, yAxisIndex: 1}
            ]
        };
        this.dataEchart.setOption(option);
        
       },

    }
  }
  </script>

<style lang="scss">
.index-list-index{
      .echart-info {
            height: 40rem;
        }
 
}
</style>
